<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "OAuth",
  methods: {
    ...mapActions(["OAuth"]),
  },
  async mounted() {
    let search = location.hash.substring(1);
    let obj = JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
    if (obj && obj.access_token) {
      if(await this.OAuth(obj.access_token)) {
        this.$router.push({name: 'MainPage'})
      } else {
        this.$router.push({name: 'MainPage'})
      }
    }
  },
};
</script>

<style>
</style>